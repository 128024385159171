<template>
  <a-modal
    destroy-on-close
    v-model="visibleMutable"
    :width="800"
    :maskClosable="false"
    :keyboard="false"
    @ok="handleOk"
    @cancel="handleCancel"
    :ok-text="okText"
    :cancel-text="cancelText"
    :confirm-loading="confirmLoading"
    :cancel-button-props="cancelButtonProps"
  >
    <a-tabs v-model="step">
      <a-tab-pane
        :key="0"
        tab="订单确认"
      >
        <modal-batch-confirm :sku="sku" ref="confirm" />
      </a-tab-pane>
      <a-tab-pane
        :key="1"
        tab="完成支付"
        :disabled="!orderNo"
      >
        <modal-batch-pay :order-no="orderNo" ref="pay" />
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { computed, onMounted, watch, ref } from '@vue/composition-api'
import Vue from 'vue'
import { message } from 'ant-design-vue'
import { wrapAwait } from '@/utils/tools'
import ModalBatchConfirm from './ModalBatchConfirm'
import ModalBatchPay from './ModalBatchPay'
import { order } from '@/api'

export default {
  name: 'ModalBatch',
  components: {
    ModalBatchConfirm,
    ModalBatchPay,
  },
  props: {
    visible: Boolean,
    sku: Object,
  },
  events: [
    'update:visible',
    'update:sku'
  ],
  setup (props, { emit }) {
    const visibleMutable = computed({
      get: () => props.visible,
      set: value => {
        emit('update:visible', value)
      }
    })

    const confirmLoading = ref(false)
    const okText = computed(() => step.value === 0 ? '确认下单' : '提交')
    const cancelText = computed(() => step.value === 0 ? '取消' : '重新下单')
    const step = ref(0)
    const cancelButtonProps = computed(() => ({
      props: {
        disabled: confirmLoading.value,
      },
      on: {
        click (event) {
          if (step.value === 0) {
            visibleMutable.value = false
          } else {
            step.value = 0
          }
        }
      }
    }))
    const confirm = ref(null)
    const pay = ref(null)
    const orderNo = ref('')
    async function handleOk () {
      if (step.value === 0) {
        orderNo.value = ''
        confirmLoading.value = true
        const [err, data] = await wrapAwait(confirm.value.submit())
        confirmLoading.value = false
        if (!err) {
          step.value = 1
          orderNo.value = data
        }
      } else {
        confirmLoading.value = true
        const [err, data] = await wrapAwait(pay.value.submit())
        confirmLoading.value = false
        if (!err) {
          visibleMutable.value = false
          message.success('提交成功')
          step.value = 0
          orderNo.value = ''
        }
      }
    }

    function handleCancel () {
      emit('update:sku', { ...props.sku })
    }

    watch(() => props.sku, () => {
      step.value = 0
      orderNo.value = ''
    })

    watch(step, async (value, oldValue) => {
      if (oldValue === 1 && value === 0) {
        if (orderNo.value) {
          const hide = message.loading('订单取消中...', 0)
          const [err] = await wrapAwait(order.deleteBatchOrder(orderNo.value))
          hide()
          if (err) message.error('取消订单失败')
        }
      }
    })

    return {
      visibleMutable,
      confirmLoading,
      okText,
      cancelText,
      step,
      handleOk,
      handleCancel,
      cancelButtonProps,
      confirm,
      orderNo,
      pay,
    }
  }
}
</script>

<style scoped>
.container {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
