<template>
  <div>
    <addr-picker v-model="selectedAddr" />

    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="false"
      row-key="productSkuId"
    >
      <template #sku="record">
        <div class="sku-item">
          <base-img
            class="goods-img"
            :src="record.skuPic || record.mainPicPath"
            width="60"
            height="60"
            oss_style="60_60"
          />
          <div class="info">
            <div class="name">{{record.productName}}</div>
            <div class="sku-name">{{record.skuName}}</div>
          </div>
        </div>
      </template>

      <template #price="record">
        {{calcUnitPrice(record)}}
      </template>

      <template #amount="record">
        <a-input style="width: 160px;" placeholder="请输入数量" v-model="record.amount">
          <template #addonBefore>
            <span class="addon-btn" @click="decrement(record)">-</span>
          </template>
          <template #addonAfter>
            <span class="addon-btn" @click="increment(record)">+</span>
          </template>
        </a-input>
        <div class="error" v-if="record.amountErr">{{record.amountErr}}</div>
      </template>

      <template #sum="record">
        <span>{{calcSum(record)}}</span>
      </template>

      <template #freight="record">
        <a-input v-model="record.freight" placeholder="请与供货商协商后填写"></a-input>
        <div class="error" v-if="record.freightErr">{{record.freightErr}}</div>
      </template>
    </a-table>

    <a-form-model>
      <a-form-model-item label="给卖家留言">
        <a-input type="textarea" v-model="comment" placeholder="请输入"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { goods } from '@/api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { EndPoint, Range, wrapAwait } from '@/utils/tools'
import AddrPicker from './AddrPicker'
import NP from 'number-precision'
import { message } from 'ant-design-vue'

export default {
  name: 'ModalBatchConfirm',
  components: {
    AddrPicker,
  },
  props: {
    sku: Object
  },
  setup (props) {
    onMounted(() => {
      fetchDefaultAttr()
    })

    const selectedAddr = ref(null)
    async function fetchDefaultAttr () {
      const [err, data] = await wrapAwait(goods.getDefaultShopAttr(getSession(SHOPID)))
      if (!err) {
        selectedAddr.value = data
      }
    }

    const columns = [
      {
        title: '商品',
        scopedSlots: {
          customRender: 'sku'
        }
      },
      {
        title: '单价（元）',
        scopedSlots: {
          customRender: 'price'
        }
      },
      {
        title: '数量',
        scopedSlots: {
          customRender: 'amount'
        }
      },
      {
        title: '金额（元）',
        scopedSlots: {
          customRender: 'sum'
        }
      },
      {
        title: '运费（元）',
        scopedSlots: {
          customRender: 'freight'
        }
      }
    ]

    const data = ref(null)
    watch(() => props.sku, () => {
      data.value = [
        {
          ...props.sku,
          amount: getDefaultAmount(props.sku.listPrice),
          freight: '',
          amountErr: '',
          freightErr: ''
        }
      ]
    }, { immediate: true, flush: 'sync' })

    function getDefaultAmount (list) {
      let min = 0
      let result = 0
      for (const item of list) {
        if (item.stepPrice < min) {
          min = item.stepPrice
          result = item.startFlag ? item.startNumStr : item.startNumStr + 1
        }
      }
      return String(Math.max(result, 1))
    }

    // 根据数量计算单价
    function calcUnitPrice (sku) {
      if (!sku.amount || Number.isNaN(+sku.amount)) return sku.price
      for (const step of sku.listPrice) {
        const range = new Range(new EndPoint(step.startNumStr, step.startFlag), new EndPoint(step.endNumStr, step.endFlag))
        const point = new EndPoint(sku.amount, true)
        if (point.isInRange(range)) {
          return step.stepPrice
        }
      }
      return sku.tradePrice
    }

    // 单价 x 数量
    function calcSum (sku) {
      if (!sku.amount || Number.isNaN(+sku.amount)) return 0
      return NP.times(calcUnitPrice(sku), +sku.amount)
    }

    // 增加数量
    function increment (record) {
      const old = +record.amount
      if (Number.isNaN(old)) {
        record.amount = 1
      } else {
        record.amount = old + 1
      }
    }

    // 减少数量
    function decrement (record) {
      const old = +record.amount
      if (Number.isNaN(old)) {
        record.amount = 1
      } else {
        record.amount = Math.max(old - 1, 1)
      }
    }

    const comment = ref('')
    async function submit () {
      if (!selectedAddr.value) {
        message.error('请选择收货地址')
        throw new Error('地址未选择')
      }
      if (!validate()) throw new Error('数量或运费填写不正确')
      const d = data.value[0]
      const [err, orderNo] = await wrapAwait(goods.createBatchOrder({
        shopId: getSession(SHOPID),
        wholesaleShopId: d.shopId,
        shopAddrId: selectedAddr.value.shopAddrId,
        productSkuId: d.productSkuId,
        count: d.amount,
        logisticsAmount: d.freight,
        comments: comment.value
      }))
      if (err) {
        message.error(err.message || '订单创建失败')
        throw new Error(err)
      } else {
        return orderNo
      }
    }

    function validate () {
      let valid = true
      for (const sku of data.value) {
        if (!sku.amount) {
          sku.amountErr = '请输入'
          valid = false
        } else if (!/^[1-9][0-9]*$/.test(sku.amount)) {
          sku.amountErr = '请输入整数'
          valid = false
        } else {
          sku.amountErr = ''
        }

        if (!sku.freight) {
          sku.freightErr = '请输入'
          valid = false
        } else if (!/^(0|[1-9][0-9]*)(\.[0-9]{1,2})?$/.test(sku.freight)) {
          sku.freightErr = '格式不正确'
          valid = false
        } else {
          sku.freightErr = ''
        }
      }
      return valid
    }

    return {
      selectedAddr,
      columns,
      NP,
      data,
      calcUnitPrice,
      calcSum,
      increment,
      decrement,
      submit,
      comment,
    }
  }
}
</script>

<style scoped>
.addon-btn {
  position: relative;
  user-select: none;
  cursor: pointer;
}
.addon-btn::before {
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  content: '';
}
.sku-item {
  display: flex;
}
.sku-item .info {
  padding-left: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.info .name {
  font-size: 14px;
  color: #333;
}
.info .sku-name {
  font-size: 12px;
  color: #999;
  padding-top: 5px;
}
.error {
  font-size: 12px;
  color: #dc0024;
  position: absolute;
}
</style>
