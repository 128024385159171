<template>
  <a-drawer
    title="编辑地址"
    placement="left"
    :closable="true"
    :visible="visibleComputed"
    @close="visibleComputed = false"
    destroy-on-close
  >
    <a-form-model
      ref="formRef"
      :model="formData"
      @submit.native.prevent
      :rules="rules"
      layout="vertical"
    >
      <a-form-model-item label="联系人：" prop="linkName">
        <a-input v-model="formData.linkName" allowClear placeholder="请填写" />
      </a-form-model-item>
      <a-form-model-item label="联系方式：" prop="linkPhone">
        <a-input v-model="formData.linkPhone" allowClear placeholder="请填写" />
      </a-form-model-item>
      <a-form-model-item label="联系地址：" prop="regionCode">
        <a-cascader
          placeholder="请选择省市区"
          v-model="formData.regionCode"
          :options="regionOptions"
          @change="regionChange"
        />
      </a-form-model-item>
      <a-form-model-item label="详细地址：" prop="addr">
        <a-input placeholder="请输入详细地址" allowClear v-model="formData.addr" />
      </a-form-model-item>
      <a-form-model-item label="地址类型：" required>
        <a-radio-group v-model="formData.addrType">
          <a-radio :value="0">
            收货地址
          </a-radio>
          <a-radio :value="1">
            收票地址
          </a-radio>
          <a-radio :value="2">
            发货地址
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="默认地址：" required>
        <a-radio-group v-model="formData.defaultFlag">
          <a-radio :value="true">
            是
          </a-radio>
          <a-radio :value="false">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="submit" :loading="saveAddrLoading">保存</a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { common, shop } from '@/api'
import { message } from 'ant-design-vue'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { phoneValidate } from '@/utils/validator'

export default {
  name: 'AddrEditor',
  props: {
    visible: Boolean,
    data: Object,
  },
  emits: ['update:visible', 'committed'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = ref(createInitData())
    function createInitData () {
      return {
        linkName: '',
        linkPhone: '',
        regionCode: [],
        defaultFlag: false,
        addrType: 0,
        province: '',
        city: '',
        county: '',
        shopAddrId: '',
        addr: '',
      }
    }
    watch(() => props.data, data => {
      if (data) {
        for (const key in formData.value) {
          if (key === 'regionCode') continue
          if (data[key] != null) formData.value[key] = data[key]
        }
        formData.value.regionCode = getRegionCodes(data.regionCode)
      } else {
        formData.value = createInitData()
      }
    }, { immediate: true })

    function getRegionCodes (regionCode) {
      if (!regionCode || regionCode.length < 4) return []
      return [
        `${regionCode.substr(0, 2)}`,
        `${regionCode.substr(0, 4)}`,
        regionCode
      ]
    }

    const regionOptions = ref([])
    onMounted(() => {
      getRegionTree()
    })
    async function getRegionTree () {
      let { data, msg, code } = await common.getRegionTree()
      if (code === '00000') {
        regionOptions.value = handleTreeData(data)
      } else {
        message.error(msg || '网络错误，请重试')
      }
    }
    function handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.label = item.regionName
        item.children = handleTreeData(item.children)
      }
      return data
    }

    function regionChange (e, selectedOptions) {
      if (selectedOptions) {
        formData.value.province = selectedOptions[0].label
        formData.value.city = selectedOptions[1].label
        formData.value.county = selectedOptions[2].label
      } else {
        formData.value.province = ''
        formData.value.city = ''
        formData.value.county = ''
      }
    }

    const saveAddrLoading = ref(false)
    function submit () {
      formRef.value.validate(async valid => {
        if (valid) {
          let param = {
            shopId: getSession(SHOPID),
            ...formData.value
          }
          param.regionCode = formData.value.regionCode[2]
          saveAddrLoading.value = true
          let { code, msg } = await shop.saveShopAddr(param)
          saveAddrLoading.value = false
          if (code === '00000') {
            visibleComputed.value = false
            message.success('保存成功')
            emit('committed')
          } else {
            message.error(msg || '保存失败')
          }
        }
      })
    }

    const rules = {
      linkName: [
        { required: true, message: '请输入联系人姓名', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的联系人姓名', trigger: 'change' }
      ],
      linkPhone: [
        { required: true, message: '请输入联系方式', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      regionCode: [
        { required: true, message: '请选择联系地址', trigger: 'change' }
      ],
      addr: [
        { required: true, message: '请输入详细地址', trigger: 'change' },
        { max: 128, message: '您可以尝试一下简短的详细地址', trigger: 'change' }
      ]
    }

    const visibleComputed = computed({
      get: () => props.visible,
      set: value => {
        emit('update:visible', value)
      }
    })

    return {
      formRef,
      regionOptions,
      regionChange,
      submit,
      rules,
      visibleComputed,
      formData,
      saveAddrLoading,
    }
  }
}
</script>
