<template>
  <div class="page-box">
    <a-skeleton v-if="loading" />
    <template v-else>
      <div v-for="item in data.filter(x => x.listPrice && x.listPrice.length)" :key="item.productSkuId"
        class="batch-sku"
      >
        <base-img :src="item.skuPic || item.mainPicPath" width="120" height="120" oss_style="240_240" />
        <div class="info">
          <div class="name">
            {{item.productName}}
            <span class="sku-name" v-if="item.skuName">规格：{{item.skuName}}</span>
            <span class="sku-name" style="margin-left: 20px;" v-if="item.stock != null">库存：{{item.stock}}</span>
          </div>
          <table class="step-table">
            <colgroup>
              <col style="width: 100px;" />
              <col v-for="sub in item.listPrice" :key="sub.productSkuStepPriceId" style="width: 140px;" />
            </colgroup>
            <tbody>
              <tr class="price-row">
                <th scope="row">批发价</th>
                <td v-for="sub in item.listPrice" :key="sub.productSkuStepPriceId">{{sub.stepPrice}}</td>
              </tr>
              <tr>
                <th scope="row">供货价</th>
                <td class="stale" :colspan="item.listPrice.length">
                  {{item.tradePrice}}
                </td>
              </tr>
              <tr>
                <th scope="row">批发数量</th>
                <td v-for="sub in item.listPrice" :key="sub.productSkuStepPriceId">
                  <template v-if="sub.startNumStr && sub.endNumStr">
                    {{sub.startNumStr}}<span class="include-badge" v-if="sub.startFlag">含</span> ~ {{sub.endNumStr}}<span class="include-badge" v-if="sub.endNumStr && sub.endFlag">含</span>
                  </template>
                  <template v-else-if="sub.startNumStr">
                    ≥{{sub.startNumStr}}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <a-button :disabled="!item.stock" style="margin-top: 15px;" @click="showBatchModal(item)">立即批发</a-button>
        </div>
      </div>
    </template>

    <modal-batch :visible.sync="batchModalVisibleFlag" :sku.sync="currentSku" />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { goods } from '@/api'
import ModalBatch from './ModalBatch'

export default {
  name: 'PageBatchDetail',
  components: {
    ModalBatch,
  },
  props: {
    productId: String,
  },
  setup (props) {
    const data = ref([])
    const loading = ref(false)
    onMounted(() => {
      getData()
    })
    async function getData () {
      loading.value = true
      const { data: d } = await goods.getProductBatchDetail(props.productId)
      loading.value = false
      data.value = d
    }

    const batchModalVisibleFlag = ref(false)
    const currentSku = ref(null)
    function showBatchModal (sku) {
      currentSku.value = sku
      batchModalVisibleFlag.value = true
    }

    return {
      data,
      loading,
      showBatchModal,
      currentSku,
      batchModalVisibleFlag,
    }
  }
}
</script>

<style lang="less" scoped>
.batch-sku {
  display: flex;
  position: relative;
  padding: 20px 0;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    border-bottom: 1px solid #eee;
  }
}
.batch-sku>:first-child {
  flex: 0 0 auto;
}
.batch-sku>:last-child {
  flex: 1 0 auto;
}
.info {
  padding-left: 20px;
}
.name {
  font-size: 18px;
}
.sku-name {
  font-size: 14px;
  color: #999;
  margin-left: 10px;
}
.include-badge {
  font-size: 10px;
  color: #999;
}
.def {
  display: flex;
  padding: 5px 0;
}
.def .def-value {
  padding: 0 15px;
}
.step-table {
  table-layout: fixed;
  background: #eee;
  text-align: center;
  border-collapse: collapse;
  margin-top: 10px;
}
.step-table th, .step-table td {
  padding: 5px 10px;
  border: 1px solid #333;
}
.step-table td {
  color: #333;
}
.price-row td {
  color: #dc0024;
  font-weight: bold;
}
.stale {
  text-decoration: line-through;
}
</style>
