<template>
  <div class="attr-container">
    <div>收货地址：
      <template v-if="value">
        <span>{{fullAttr}}</span>
        <span>{{value.linkName}}</span>
        <span>{{value.linkPhone}}</span>
      </template>
      <a-button type="link" @click="showAddrPickerFlag = !showAddrPickerFlag">{{showAddrPickerFlag ? '收起' : '展开'}}</a-button>
    </div>
    <a-spin class="list-container" v-if="showAddrPickerFlag" :spinning="loading">
      <a-list item-layout="horizontal" :data-source="attrs">
        <!-- old syntax for compatibility -->
        <a-list-item
          slot="renderItem"
          slot-scope="item"
          @click.native="$emit('change', item)"
          style="cursor: pointer"
        >
          <a-list-item-meta
            :description="`${item.province}${item.city}${item.county}${item.addr}`"
          >
            <template #title>
              {{`${item.linkName} ${item.linkPhone}`}}
              <a-tag style="margin-left: 10px;" v-if="item.defaultFlag" color="blue">默认</a-tag>
              <a-button
                v-else
                size="small"
                :loading="setDefaultLoading === item.shopAddrId"
                @click.stop="setDefault(item)"
                type="link"
              >设为默认</a-button>
            </template>
            <template #avatar>
              <a-radio
                :checked="item.shopAddrId === value.shopAddrId"
              ></a-radio>
            </template>
          </a-list-item-meta>

          <template #actions>
              <a-button type="link" @click="editAddr(item)">修改</a-button>
          </template>
        </a-list-item>
      </a-list>
      <div class="tip">以上地址都不合适？<a-button type="link" @click="addAddr">去新增地址</a-button></div>
      <a-button :loading="loading" class="refresh" type="primary" size="small" shape="circle" icon="sync" @click="fetchAttrs"></a-button>
    </a-spin>

    <addr-editor
      :visible.sync="showAddrEditorFlag"
      :data="currentAddr"
      @committed="fetchAttrs"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { wrapAwait } from '@/utils/tools'
import { goods, shop } from '@/api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import AddrEditor from './AddrEditor'

export default {
  name: 'AddrPicker',
  components: {
    AddrEditor,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  emits: ['change'],
  props: {
    value: Object
  },
  setup (props, { emit }) {
    const fullAttr = computed(() => {
      let a
      if ((a = props.value)) {
        return `${a.province}${a.city}${a.county}${a.addr}`
      }
    })

    const showAddrPickerFlag = ref(false)
    const attrs = ref([])
    const loading = ref(false)
    onMounted(fetchAttrs)

    async function fetchAttrs () {
      loading.value = true
      const [err, data] = await wrapAwait(goods.getAllAttr(getSession(SHOPID)))
      loading.value = false
      if (!err) {
        attrs.value = data
      }
    }

    const showAddrEditorFlag = ref(false)
    const currentAddr = ref(null)
    function addAddr () {
      showAddrEditorFlag.value = true
      currentAddr.value = null
    }

    function editAddr (addr) {
      showAddrEditorFlag.value = true
      currentAddr.value = addr
    }

    const setDefaultLoading = ref('')
    async function setDefault (addr) {
      setDefaultLoading.value = addr.shopAddrId
      const { code } = await shop.saveShopAddr({
        ...addr,
        defaultFlag: true
      })
      setDefaultLoading.value = ''
      if (code === '00000') {
        attrs.value.forEach(x => {
          x.defaultFlag = x.shopAddrId === addr.shopAddrId
        })
      }
    }

    return {
      fullAttr,
      showAddrPickerFlag,
      attrs,
      loading,
      fetchAttrs,
      showAddrEditorFlag,
      currentAddr,
      addAddr,
      editAddr,
      setDefault,
      setDefaultLoading,
    }
  }
}
</script>

<style scoped>
.attr-container {
  padding-bottom: 15px;
}
.list-container {
  position: relative;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.refresh {
  position: absolute;
  top: 10px;
  right: 0;
}
</style>
